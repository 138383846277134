import axios from 'axios'
 
 
var request = axios.create({
    // 后台接口的基准地址
    baseURL:"https://www.cylbm.cn",
    timeout:5000
})
 
// 添加请求拦截器
request.interceptors.request.use((config) => {
    return config
}, function (error) {
    //对相应错误做点什么
    return Promise.reject(error)
}
)
 
 
 
//拦截器响应
request.interceptors.response.use((response) => {
    if(response.data.res === '100' || response.data.RES === '100'){
        return response.data
    }
    alert("请求出错")
    return Promise.reject("1234")
}, function (error) {
    //对相应错误做点什么
    return Promise.reject("1234")
}
)
 
export default request