import React, { Component } from 'react'

export default class TagList extends Component {
  render() {
    return (
        <div className='tagCon'>
        {
            this.props.tagList.map((item,index)=>{
                return (
                    <div key={'tag' + index} className='tagItem'>
                        <div>{item.name}</div>
                    </div>
                )
            })
        }
    </div>
    )
  }
}
