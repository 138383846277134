import React, { Component } from 'react'

export default class FollowMe extends Component {
  render() {
    return (
        <div className="rightTop">
            <div style={{width:"100px",padding:"5px",border:"1px solid var(--border-color)",margin:"10px auto"}}>
              <img src="image/gzhewm.jpg" width={100} alt="我爱学前端" />
            </div>
            <div>关注我的公众号</div>
            <div>
            <div>→「面试经验」供你参考</div>
            <div>→「免费资料」供你领取</div>
            <div>→「技术干货」传授经验</div>
            <div style={{marginTop:"10px",color:"var(--text-hover)"}}>扫描上方二维码，关注我的公众号</div>
            <div>更多干货，等你来看，我在微信上等你！</div>
            </div>
        </div>
    )
  }
}
