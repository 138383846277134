import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import "./index.css"
export default class Item extends Component {
  
  render() {
    return (
        <Link to={"/articleDetails?id=" + this.props.id} className='ListItem'>
            <div className='divImg'>
                <img src={'http://cy.cylbm.cn/'+this.props.image_url} alt="我爱学前端" width={"100%"}  />
            </div>
            <div>
                <div>{this.props.title}</div>
                <div>{this.props.content_short}</div>
                <div>
                    <div >
                    {/* <div>已读: {this.props.readNum}</div> */}
                    </div>
                    <div>
                        <div>{this.props.typeacChild}</div>
                    </div>
                </div>
            </div>
        </Link>
    )
  }
}
