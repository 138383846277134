import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { getSubjectList } from '../../api'
import "./index.css"

export default class ArticleIItem extends Component {
  state = {
    articleList:[]
  }
  componentDidMount(){
    getSubjectList({
      url:"http://cy.cylbm.cn/Management/ajax/updatePro.ashx?type=Loadproductlist",
      method:"get",
      params:{
        len:5,fy:1,typeac:"all"
      }
    }).then(res=>{
      this.setState({
        articleList:res.detalis
      })
    })
  }
  render() {
    return (
      <div>
        {
          this.state.articleList.map((item,index) => {
            return (
              <Link title={'我爱学前端--'+item.title} to={"/articleDetails?id=" + item.id} className='aItem' key={'t'+index}>
                <div className='pushImg'>
                    <img src={"http://cy.cylbm.cn/" + item.image_url} width={'100%'} alt="" />
                </div>
                <div className='textCon'>
                    <div>{item.title}</div>
                    <div>{item.content_short}</div>
                    <div>
                        <div>{(new Date(item.displayTime).toLocaleDateString())}</div>
                        <div>继续阅读</div>
                    </div>
                </div>
              </Link>
            )
          })
        }
      
      </div>
    )
  }
}
