import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import qs from 'qs'
import { getSubjectList } from '../../../../api'
export default class LxSubject extends Component {
  state={
    type:qs.parse(this.props.location.search.slice(1)).type,
    icon:qs.parse(this.props.location.search.slice(1)).i,
    details:[],
    page:1,
    loading:false,
    count:0
  }
  refElement = React.createRef();
  goBack = ()=>{
    this.props.history.goBack();
  }
  LoadSubList = ()=>{
    if(this.state.loading){
      return ;
    }
    this.setState({
      loading:true
    })
    getSubjectList({
      url:"/ajax/waxqd.ashx?type=loadqusetionlisttype",
      method:"get",
      params:{
        kcname:this.state.type.toLocaleLowerCase(),
        ym:this.state.page,
        len:20
      }
    }).then(res => {
    
      this.setState({
        details:this.state.details.concat(res.detalis),
        loading:false,
        num:this.state.page + 1,
        count:res.count
      })
    })
  }
  HandleScroll = ()=>{
   // console.log(this.refElement)
   let scrollHeight = document.documentElement.scrollTop + document.documentElement.clientHeight;
    if(
      scrollHeight >= 
      this.refElement.current.clientHeight - 150 &&
      this.state.details.length < this.state.count ){
        this.LoadSubList()
      }

  }
  componentDidMount(){
    window.addEventListener('scroll', this.HandleScroll,true)
    this.LoadSubList()
    
  }
  componentWillUnmount(){
    window.removeEventListener("scroll", this.HandleScroll,true)
  }
  render() {
    return (
      <div ref={this.refElement}>
        <div className='subHeader'>
          <div onClick={()=>this.goBack()}>&lt;</div>
          <div>{this.state.type + '面试题'}</div>
        </div>  
        <div>
          {
            this.state.details.map((item,index) => {
              return (
                <Link className='subLxItem' to={'/InterViewDetails?exerciseKey=' + item.exerciseKey + '&type=' + this.state.type + '&num=' + (index + 1)} key={index}>
                  <img src={"image/" + this.state.icon + ".png"} width={50} alt="我爱学前端" />
                  <div className='siDivInfo'>
                    <div>{(index + 1) + "、" + unescape(item.title)}</div>
                    <div>问答题</div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    )
  }
}
