import React, { Component } from 'react'
import "./index.css"

export default class Logo extends Component {
    state = {
        markCon:[
            {
                name:"文章",
                num:"看"
            },
            {
                name:"科目",
                num:"逛"
            },
            {
                name:"标签",
                num:"聊"
            }
        ]
    }
  render() {
    return (
      <div className='content'>
        <img src="image/logo.png" alt='我爱学前端' style={{marginTop:".5rem"}} width={88} />
        <div className='logoName'>我爱学前端</div>
        <div className='descText'>更正确的全面学习WEB前端知识</div>
        <div className='markCon'>
            {
                this.state.markCon.map((item,index) => {
                    return (
                        <div key={index}>
                            <div>{item.num}</div>
                            <div>{item.name}</div>
                        </div>
                    )
                })
            }

        </div>
        <div className='markCon'>
            <a href='https://mp.weixin.qq.com/s?__biz=MzIwOTYyMzc0OA==&amp;mid=2247483907&amp;idx=1&amp;sn=e61ff71757090b3e58879f74b0e7171a&amp;chksm=97704da8a007c4be7a3c681864df52c5525c24e8e3252c54bfe5f3421a0318e876d7ef1ba95d&token=266030086&lang=zh_CN#rd' target='_blank'>
                <i className='iconfont icon-weixin'></i>
            </a>
            <a href='https://gitee.com/' target='_blank'>
                <i className='iconfont icon-gitee'></i>
            </a>
            <a href='https://author.baidu.com/home/1737928373504928' target='_blank'>
                <i className='iconfont icon-baidu1'></i>
            </a>
        </div>
      </div>
    )
  }
}
