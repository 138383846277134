export const eList = [
    {
        name:"Vue.js项目开发实战",
        url:"https://pan.baidu.com/s/1qN5fsNjZG9RxOWRCPQQo6A",
        yzm:"1001"
    },
    {
        name:"Vue.js实战",
        url:"https://pan.baidu.com/s/1tkrTPnkIo36fi6eI8ihYcg",
        yzm:"1102"
    },
    {
        name:"MongoDB教程",
        url:"https://pan.baidu.com/s/1YWsjZqSEHhZPF0-WD5PF3w",
        yzm:"1002"
    },
    {
        name:"JavaScript高级程序设计（第3版）",
        url:"https://pan.baidu.com/s/1WHP0I--FaREi_mN4uzYk5w",
        yzm:"1003"
    },
    {
        name:"Node.js硬实战 115个核心技巧",
        url:"https://pan.baidu.com/s/1VYciKNonMYPQlr660tG2Eg",
        yzm:"1004"
    },
    {
        name:"Node.js微服务",
        url:"https://pan.baidu.com/s/12QTri26pjIrklfr5ap64LA",
        yzm:"1005"
    },
    {
        name:"新时期的Node.js入门",
        url:"https://pan.baidu.com/s/18GwXIIUxHhi8XR4JAuF48g",
        yzm:"1006"
    },
    {
        name:"React Node.js开发实战：从入门到项目上线_源代码",
        url:"https://pan.baidu.com/s/10eL_qFA_WXvo8uEqf9SdwA",
        yzm:"1007"
    },
    {
        name:"Go语言从入门到进阶实战",
        url:"https://pan.baidu.com/s/1Uxz07W8_63yonsQfD_-YbA",
        yzm:"1008"
    },
    {
        name:"左手MongoDB，右手Redis",
        url:"https://pan.baidu.com/s/1Pu6BjiYVFaNcHNJ7buBiDQ",
        yzm:"1009"
    },
    {
        name:"MongoDB大数据处理权威指南 第2版",
        url:"https://pan.baidu.com/s/1_RkGNBlH660BQTlRVKpZPA",
        yzm:"1010"
    },
    {
        name:"React Native入门与实战",
        url:"https://pan.baidu.com/s/1UvpviUFZ7NyakbalZemEhw",
        yzm:"1011"
    },
    {
        name:"深入浅出React和Redux",
        url:"https://pan.baidu.com/s/1gqpkmt6eoI7hdbRRgklD1g",
        yzm:"1012"
    },
    {
        name:"React学习手册",
        url:"https://pan.baidu.com/s/1ljKLac1NUSh2q-i27244JQ",
        yzm:"1013"
    },
    {
        name:"Web前端开发 HTML5+CSS3+jQuery+AJAX从学到用完美实践",
        url:"https://pan.baidu.com/s/1y_lDHtKPkwwrZy0Zq9f7KQ",
        yzm:"1014"
    },
    {
        name:" PHP&MySQL跨设备网站开发实例精粹",
        url:"https://pan.baidu.com/s/1zULxKcOh9r13so9-qzJ-5w",
        yzm:"1015"
    },
    {
        name:"JavaScript_ The Definitive Guid",
        url:"https://pan.baidu.com/s/1wAk4YxmbR74KZiM8-K7ygA",
        yzm:"1016"
    },
    {
        name:"零成本实现Web性能测试",
        url:"https://pan.baidu.com/s/1C7F-YRe-wjABng04HKZp3Q",
        yzm:"1017"
    },
    {
        name:"《微信公众平台应用开发实战（第2版）》源代码",
        url:"https://pan.baidu.com/s/126yo6skbm393jIr7fabK8A",
        yzm:"1018"
    },
    {
        name:"JavaScript核心技术开发解密",
        url:"https://pan.baidu.com/s/1tHH9BsRdWHtMmAPTAr4OEA",
        yzm:"1019"
    },
    {
        name:"jQuery实战从入门到精通",
        url:"https://pan.baidu.com/s/1qYCNsjGeDV8OJgZ0cR8tMQ",
        yzm:"1020"
    },
    {
        name:"HTML与CSS入门经典（第8版）",
        url:"https://pan.baidu.com/s/1GtJinS0NUMF5EVwfujor9Q",
        yzm:"1021"
    }
]
export const sjeList = [
    {
        name:"大话设计模式",
        url:"https://pan.baidu.com/s/1KwgkquuCdCOCa_yciPI2UQ",
        yzm:"2000"
    },
    {
        name:"HeadFirst设计模式",
        url:"https://pan.baidu.com/s/1G6CpMkIf8vTqcasiDLOgkQ",
        yzm:"2001"
    },
    {
        name:"研磨设计模式",
        url:"https://pan.baidu.com/s/1FOSLR7epmQUUnl8DzyRvnQ",
        yzm:"2002"
    },
    {
        name:"图解设计模式",
        url:"https://pan.baidu.com/s/1k3sMMriN1Dsva66DOh1hOQ",
        yzm:"2003"
    }
]
export const jsjeList = [
    {
        name:"正则表达式",
        url:"https://pan.baidu.com/s/1vMpnqUj5Md9uik3ScOxSjQ",
        yzm:"3000"
    },
    {
        name:"计算机编译原理",
        url:"https://pan.baidu.com/s/1F-KVoIV2OSfSGgdC9amsyw",
        yzm:"3001"
    },
    {
        name:"计算机组成原理",
        url:"https://pan.baidu.com/s/1xvK3k7a8H0qRWHTMsCtQiQ",
        yzm:"3002"
    },
    {
        name:"汇编语言",
        url:"https://pan.baidu.com/s/17iCjNrSzKHXANIqp3L0v4Q",
        yzm:"3003"
    },
    {
        name:"操作系统",
        url:"https://pan.baidu.com/s/16A8TCci3lcI4CKqtgNW7pQ",
        yzm:"3004"
    }
]