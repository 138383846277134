import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class QuickNav extends Component {
  render() {
    return (
      <div>
        <div className='qTitle'>快捷导航</div>

        {
            this.props.qNabList.map((item,index) => {
               return (
                <div name="我爱学前端" className='subItem' key={index}>
                    <div>{item.name}</div>
                    <div>
                        {item.children.map((it,ix) => {
                            return (
                                <Link replace={true} to={it.path} key={'c' + ix}>{it.name}</Link>
                            )
                        })}
                    </div>
                </div>
               )
            })
        }


      </div>
    )
  }
}
