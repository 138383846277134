import React, { Component } from 'react'

export default class MiniApp extends Component {
  render() {
    return (
        <div className='tagCon miniApp'>
        <div>
            <div className='zzImg'>
                <div>微信扫一扫</div>
                <img src="image/shjzewm.jpg" width={"76%"} alt="我爱学前端" />
            </div>
            <img src="image/zdlog.png" width={"70%"} alt="我爱学前端" />
            <div>生活记账</div>
        </div>
        <div>
        <div className='zzImg'>
                <div>微信扫一扫</div>
                <img src="image/waxqdewm.jpg" width={"76%"} alt="我爱学前端" />
            </div>
            <img src="image/waxqdlg.png"  width={"70%"} alt="我爱学前端" />
            <div>吾爱学前端</div>
        </div>
        <div>
        <div className='zzImg'>
                <div>微信扫一扫</div>
                <img src="image/yyfgewm.jpg" width={"76%"} alt="我爱学前端" />
            </div>
            <img src="image/yyfdlogo.png"  width={"70%"} alt="我爱学前端" />
            <div>圆圆辅导</div>
        </div>
        {/* <div>
            <img src="image/logo.png"  width={"70%"} alt="" />
            <div>圆圆坦克</div>
        </div> */}
    </div>
    )
  }
}
