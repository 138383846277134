import React, { Component } from 'react'
import './index.css'
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div>&copy; 2023 WAXQD.COM  &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="http://www.waxqd.com">我爱学前端</a>
        </div>
        <div>友情链接：<a href="http://www.cylbm.cn">赤缘来帮忙</a></div>
        <div>
            <a href="https://beian.miit.gov.cn/">网站备案/许可证号:湘ICP备16019244号-2</a>
        </div>
        <div className='imgdiv'>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=43090302000288" rel="noreferrer" target="_blank">
            <img src='/image/batb.png' />
            湘公网安备43090302000288
            </a>
        </div>
      </footer>
    )
  }
}
