export const navList = [
    {
        name:"首页",
        path:"/",
        icon:""
    },
    {
        name:"电子书下载",
        path:"/ebook",
        icon:""
    },
    {
        name:"面试",
        path:"/interview",
        icon:""
    },
    // {
    //     name:"分类",
    //     path:"/subject",
    //     icon:""
    // },
    {
        name:"友链",
        path:"/friendLink",
        icon:""
    },
    
    {
        name:"模式",
        path:"/mode",
        icon:""
    }
    // ,
    // {
    //     name:"搜索",
    //     path:"/search",
    //     icon:""
    // }
]