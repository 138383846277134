import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {navList} from "./data.js"
import "./index.css"
export class Header extends Component {
  state = {
    navList
  }
  modeChange = ()=>{
      const docElm = document.documentElement
    if (!docElm.hasAttribute('theme')) {
      docElm.setAttribute('theme', "dark")
    } else {
      docElm.removeAttribute('theme')
    }
  }
  render() {
    return (
      <header>
        <div>
          <div className='logoCon'>
            <img src="image/logo.png" width={40} alt='我爱学前端' />
            <div title='我爱学前端'>我爱学前端</div>
          </div>
          <nav>
            {/* <Router> */}
            {
              navList.map((item,index)=>{
                if(item.name === "模式"){
                  return (
                  <div  onClick={()=>{this.modeChange()}} key={index}>{item.name}</div>
                  )
                }
                return (
                  <Link title={'我爱学前端-'+item.name} to={item.path} key={index}>{item.name}</Link>
                )
              })
            }
            {/* </Router> */}
          </nav>
        </div>
      </header>
    )
  }
}

export default Header