import React, { Component } from 'react'
import './index.css'
import { eList ,sjeList,jsjeList} from './data'
export default class Ebook extends Component {
    state = {
        showW:false,
        url:"#",
        yzmCode:"1234"
    }
    ShowWindowHtml = ()=>{
        if(this.state.showW){
            return (
<div className='showWindow'>
            <div className='swCon'>
                <div className='closeIcon' onClick={()=>this.showWd()}>×</div>
                <div>
                    <a target='_blank' href={this.state.url}>下载地址：网盘链接</a>
                    <div>
                        网盘密码：关注公众号，回复<span style={{color:"var(--post-link-color)"}}>{this.state.yzmCode}</span>即可获取
                    </div>
                </div>
                <div>
                    <img src="image/gzhewm.jpg" alt="" width={200} />
                </div>
                <div style={{fontSize:".8rem",marginTop:".5rem"}}>
                    公众号进行了身份识别，取消关注后将无法使用此功能
                </div>
            </div>
        </div>
            )
        }
        return ""
    }
    showWd = (it)=>{
       this.setState({
        url:it?.url,
        yzmCode:it?.yzm,
        showW:!this.state.showW
       })
    }

  render() {
    return (
      <div className='eBcon'>
        {
            this.ShowWindowHtml()
        }
        <img src="image/ebooklogo.jpg" width={'100%'} alt="我爱学前端" />
        <div className='infoCon'>
            <div className='psText'>
            声明：本站资源整理于互联网以及百度网盘，用来学习交流，如果有版权或者其他问题可以直接联系小编立即处理。联系邮箱：<span>30273908@qq.com</span>。
            </div>
            <div className='ecbookTitle'>
                web前端学习
            </div>
            <div className='ecbookCon'>
                {
                    eList.map((item,index) => {
                        return (
                            <div key={'rr' + index} className='ecbookItem' onClick={()=>this.showWd(item)}>
                                <div>{item.name}</div>
                                <div>下载</div>
                            </div>
                        )
                        
                    })
                }
            </div>
            <div className='ecbookTitle'>
                设计模式
            </div>
            <div className='ecbookCon'>
            {
                    sjeList.map((item,index) => {
                        return (
                            <div key={'sj' + index} className='ecbookItem' onClick={()=>this.showWd(item)}>
                                <div>{item.name}</div>
                                <div>下载</div>
                            </div>
                        )
                        
                    })
                }
            </div>
            <div className='ecbookTitle'>
                计算机基础
            </div>
            <div className='ecbookCon'>
            {
                    jsjeList.map((item,index) => {
                        return (
                            <div key={'jsj' + index} className='ecbookItem' onClick={()=>this.showWd(item)}>
                                <div>{item.name}</div>
                                <div>下载</div>
                            </div>
                        )
                        
                    })
                }
            </div>
        </div>
      </div>
    )
  }
}
