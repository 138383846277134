export const subjectList = [
    {
        name:"HTML",
        num:10,
        path:""
    },
    {
        name:"HTML",
        num:10,
        path:""
    },
    {
        name:"HTML",
        num:10,
        path:""
    },
    {
        name:"HTML",
        num:10,
        path:""
    },
    {
        name:"HTML",
        num:10,
        path:""
    },
    {
        name:"HTML",
        num:10,
        path:""
    }
]
export const  tagList = [
    {
        name:"HTML",
        path:""
    },
    {
        name:"CSS",
        path:""
    },
    {
        name:"前端",
        path:""
    },
    {
        name:"JS",
        path:""
    },
    {
        name:"Vue",
        path:""
    },
    {
        name:"React",
        path:""
    },
    {
        name:"Jquery",
        path:""
    },
    {
        name:"webpack",
        path:""
    },
]
export const qNabList = [
    {
        name:"HTML",
        children:[
            { name:"HTML5+CSS3",path:"/subject?type=html&ctype=css"},
            { name:"LESS",path:"/subject?type=html&ctype=less"},
            { name:"SASS",path:"/subject?type=html&ctype=sass"},
        ]
    },
    {
        name:"JAVASCRIPT",
        children:[
            { name:"JS入门",path:"/subject?type=js&ctype=js入门"},
            { name:"JS高级",path:"/subject?type=js&ctype=js高级"},
            { name:"ES6",path:"/subject?type=js&ctype=es6"},
        ]
    }
]