import React, { Component } from 'react'
import Item from '../item/Item'
import "./index.css"
import qs from 'qs'
import { getSubjectList } from '../../api'
export default class SubRouter extends Component {
    state = {
        type:qs.parse(this.props.location.search.slice(1)).type,
        ctype:qs.parse(this.props.location.search.slice(1)).ctype,
        list:[]
    }
    componentWillReceiveProps(x){
      document.documentElement.scrollTop = 0;
        this.setState({
          type:qs.parse(x.location.search.slice(1)).type,
          ctype:qs.parse(x.location.search.slice(1)).ctype,
          list:[]
        })
      //alert(qs.parse(x.location.search.slice(1)).ctype)
      this.getList(qs.parse(x.location.search.slice(1)).type,qs.parse(x.location.search.slice(1)).ctype);

    }
    componentDidMount(){
      this.getList(qs.parse(this.props.location.search.slice(1)).type,qs.parse(this.props.location.search.slice(1)).ctype);
      
  }
  getList(type,ctype){
    getSubjectList({
      url:"http://cy.cylbm.cn/Management/ajax/updatePro.ashx?type=Loadproductlist",
      method:"get",
      params:{
          len:20,fy:1,typeac:type,typeacChild:ctype
      }
  }).then(res => {
      this.setState({
        list:res.detalis
      })
  }).catch((err)=>{
 //   alert(err)
  })
  }
  render() {
    return (
      <div>
        <div className='SubTitle'>{
            qs.parse(this.props.location.search.slice(1)).name
          
        }</div>
       {
        this.state.list.map((item,index) => {
          return (
            <div key={"ssd"+index}>
              <Item {...item} />
            </div>
          )
        })
       }
      </div>
    )
  }
}
