import React, { Component } from 'react'
import './index.css'
export default class FrinedLink extends Component {
    state = {
        linkList:[
            {name:"我的公众号",icon:"http://wx.qlogo.cn/mmopen/H746IicsWHDIdjAFy1HOn7OIPQlaIqicWeD73wO6iccn1RlZ6f9Vzibw0YP4FzPdHEj3ygmD7Sh1RzwltNetv4TYTc9jYV1j0qAv/64",path:"https://mp.weixin.qq.com/s?__biz=MzIwOTYyMzc0OA==&amp;mid=2247483907&amp;idx=1&amp;sn=e61ff71757090b3e58879f74b0e7171a&amp;chksm=97704da8a007c4be7a3c681864df52c5525c24e8e3252c54bfe5f3421a0318e876d7ef1ba95d&token=266030086&lang=zh_CN#rd"},
            {name:"我的Gitee",icon:"https://ecmb.bdimg.com/tam-ogel/-440932281_-336120762_88_88.png",path:"https://gitee.com"},
            {name:"我的知乎",icon:"https://static.zhihu.com/heifetz/assets/apple-touch-icon-152.81060cab.png",path:"https://zhihu.com"},
            {name:"我的B站",icon:"https://gimg3.baidu.com/search/src=https%3A%2F%2Fgips0.baidu.com%2Fit%2Fu%3D2601212409%2C313616066%26fm%3D3012%26app%3D3012%26autime%3D1665724334%26size%3Db360%2C360&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=w931&n=0&g=0n&q=75&fmt=auto?sec=1682355600&t=2c36a404807892d0c3bbad2884532980",path:"https://www.bilibili.com/"},
            {name:"赤缘来帮忙",icon:"http://www.cylbm.cn/images/logo.png",path:"http://www.cylbm.cn"},
            {name:"我的百家号",icon:"https://gimg3.baidu.com/search/src=https%3A%2F%2Fgips0.baidu.com%2Fit%2Fu%3D4017416496%2C592544203%26fm%3D3012%26app%3D3012%26autime%3D1681356872%26size%3Db360%2C360&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=w931&n=0&g=0n&q=75&fmt=auto?sec=1682355600&t=4a7551a29ae6eed085ae6c1ba3dc66a4",path:"https://author.baidu.com/home/1737928373504928"},
        ]
    }
  render() {
    return (
      <div className='friendLinkCon'>
        <div className='title'>友情链接：</div>
        <div className='Fcon'>
            <div>
                {
                    this.state.linkList.map((it,ix)=>{
                        return (
                            <a href={it.path} target='_blank'>
                                <img src={it.icon} width={60} alt="我爱学前端" />
                                {it.name}
                            </a>
                        )
                    })
                }
            </div>
        </div>

      </div>
    )
  }
}
