import React, { Component } from 'react'
import { HashRouter as Router , Route, } from 'react-router-dom'
import Header  from './components/header'
import Logo from './components/slideLeft/logo/Logo'
import Type from './components/slideLeft/type/Type'
import ArticleIItem from './components/article/ArticleIItem'
import SubRouter from './components/subjectRouter/SubRouter'
import Footer from './components/footer/Footer'
import Ebook from './view/ebook/Ebook'
import FrinedLink from './view/friendLink/FrinedLink'
import InterView from './view/interview/InterView'
import InterViewDetails from './view/interViewDetails/InterViewDetails'
import ArticleDetails from './view/articleDetails/ArticleDetails'
export class App extends Component {
  state = {
    show:false
  }
  componentDidMount(){
   // document.addEventListener("scroll",this.showTop,true)
  }
  showTop = ()=>{
    if(document.documentElement.scrollTop > 200){
      this.setState({
        show:true
      })
    }else{
      this.setState({
        show:false
      })
    }
  }
  goTop = ()=>{
    
    if(this.state.show){
      return (
        <div className='goTop'>TOP</div>
      )
    }else{
      return ("")
    }
  }
  render() {
    return (
      <div>
        <Router >
        <Header />
        <div className='container'>
          <aside className='leftAside'>
            <Logo />
            <Type direction="left" />
          </aside>
          <main>
            <div>
              
                <Route path="/" component={ArticleIItem} exact />
                <Route path="/subject" component={SubRouter} exact />
                <Route path="/ebook" component={Ebook} />
                <Route path="/friendLink" component={FrinedLink} />
                <Route path="/interview" component={InterView} />
                <Route path="/InterViewDetails" component={InterViewDetails} />
                <Route path="/articleDetails" component={ArticleDetails} />
                
              
            </div>
          </main>
          <aside className='rightAside'>
            <Type direction="right" />
          </aside>
        </div>
        </Router>
        <Footer />
        {this.goTop()}
      </div>
    )
  }
}

export default App