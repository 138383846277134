import React, { Component } from 'react'
// import Highlight from 'react-highlight'
// import 'highlight.js/styles/vs2015.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import qs from 'qs'
import { getSubjectList } from '../../api'
import "./index.css"
export default class InterViewDetails extends Component {
    state = {
        exerciseKey:qs.parse(this.props.location.search.slice(1)).exerciseKey,
        type:qs.parse(this.props.location.search.slice(1)).type,
        num:qs.parse(this.props.location.search.slice(1)).num,
        detailTitle:"",
        details:""
    }
    componentDidMount(){
      document.documentElement.scrollTop = 0;
      this.LoadDetails()
    }
    LoadDetails(){
        getSubjectList({
            url:"/ajax/waxqd.ashx?type=questionDetails",
            method:"get",
            params:{
                exerciseKey:this.state.exerciseKey
            }
        }).then(res => {
            console.log(res);
            this.setState({
                details:unescape(res.detalis[0].explanation),
                detailTitle:unescape(res.detalis[0].title)
            })
        })
    }
    nextAsk(num,type){
     
      if(num <= 0){
        alert("没有更多题目了");
        return 
      }

      getSubjectList({
        url:"/ajax/waxqd.ashx?type=questionOther",
        method:"get",
        params:{
          kcname:this.state.type,
          len: this.state.num,
          state:type
        }
    }).then(res => {
      document.documentElement.scrollTop = 0;
        this.setState({
            details:unescape(res.detalis[0].explanation),
            detailTitle:unescape(res.detalis[0].title),
            num
        })
    })
    }
  render() {
    return (
      <div className='detailsCon'>
        <div className='detailTitle'>
          <div onClick={()=>this.props.history.goBack()}>&lt;</div>
          {this.state.type + '面试题详解'}
          {/* {this.state.detailTitle} */}
        </div>
        <div className='detailTitleCon'>
          {'第' + this.state.num + '题：' + this.state.detailTitle}
        </div>
        {/* <Highlight className={this.state.type}> */}
        <div className='mdText'>
        <ReactMarkdown children={this.state.details} 
        components={{
          code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                {...props}
                children={String(children).replace(/\n$/, '')}
                style={dark}
                language={match[1]}
                PreTag="div"
              />
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            )
          }
        }}
        />
        </div>
        {/* </Highlight>  */}
        <div className='buttomDiv'>
          <div  onClick={()=>this.props.history.goBack()}>&lt;</div>
          <div>
            <div onClick={()=>{this.nextAsk(this.state.num - 1,'prev')}}>{this.state.num == 1 ? '没有更多了':'上一题'}</div>
            <div onClick={()=>{this.nextAsk(this.state.num - 0 +1,'next')}}>下一题</div>
          </div>
        </div>
      </div>
    )
  }
}
