import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { getSubjectList } from '../../../../api/index.js'
import { msSubjectList } from '../../data.js'
export default class InterViewIndex extends Component {
    state = {
        msSubjectList,
        detalis:[]

    }
    componentDidMount(){
      this.loadMsList();
    }
    loadMsList = ()=>{
      getSubjectList({
        url:"https://www.cylbm.cn/ajax/waxqd.ashx?type=loadqmsList",
        method:"get",
        params:{
          len: 100,
          ym:1
        }
      }).then(res=>{
        let detalis = [[],[],[]]
        res.detalis.forEach(item=>{
          detalis[item.type].push(item)
        })
        this.setState({
          detalis
        })
      })
    }
  render() {
    return (
      <div>
        <div className='msTitle'>一、面试题目</div>
        <div className='lxCon'>
            
        {this.state.msSubjectList.map((item,index)=>{
            if(item.path == ''){
              return (
                <div key={index}>
                  <div to={item.path}>
                      <img src={'image/'+item.icon} alt="我爱学前端" width={50} />
                      <div>{item.name}</div>
                  </div>
                </div>
              )
            }else{
              return (
                <div key={index} title={'我爱学前端'+item.name}>
                    <Link to={item.path}>
                      <img src={'image/'+item.icon} alt="我爱学前端" width={50} />
                      <div>{item.name}</div>
                    </Link>   
                </div>
                )
            }
            
        })}
            
        </div>
        {
          this.state.detalis.map((item,index)=>{
            return (
              <div className='mscon' key={'ms'+index}>
                <div className='msTitle'>{
                  index == 0 ? '二、面试经验' : index == 1 ? '三、技术要点' : '四、面试技巧'
                }</div>
                  <div>
                      
                  {item.map((it,inx)=>{
                      return (
                      <div  className='msConItem' key={'mm'+inx}>
                          <a target='_blank' href={it.url}>
                              {(inx + 1) + "、" + it.name}
                          </a>
                          
                      </div>
                      )
                  })}
            
        </div>

              </div>
            )
          })
        }
        
      </div>
    )
  }
}
