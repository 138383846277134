import React, { Component } from 'react'

export default class TypeChild extends Component {
  render() {
    return (
        <div className='typeCon'>
            <div>{this.props.title}</div>
            {
            this.props.children
            }
        </div>
      
    )
  }
}
