import React, { Component } from 'react'

import {Route,Switch} from 'react-router-dom'
import LxSubject from './children/lx/LxSubject'
import InterViewIndex from './children/index/InterViewIndex'

import './index.css'
export default class InterView extends Component {
    
  render() {
    return (
        
      <div className='InterCon'>
        
        <Switch>
            <Route path="/interview" exact  component={InterViewIndex}  />
            <Route path="/interview/lx" component={LxSubject} />
            
        </Switch>
      </div>
    )
  }
}
