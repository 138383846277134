import request from "../utils/request";
export const getSubjectList = (data) => {
    /**
     * data:{
     *  url: url,
        method: method,
        params: data
     * }
     *  
     */
    return request({
        ...data
    })
}