export const msSubjectList = [
    {
        name:"HTML",
        icon:"HTML.png",
        path:"interview/lx?type=HTML&i=HTML"
    },
    {
        name:"CSS",
        icon:"CSS.png",
        path:"interview/lx?type=CSS&i=CSS"
    },
    {
        name:"JavaScript",
        icon:"JavaScript.png",
        path:"interview/lx?type=JavaScript&i=JavaScript"
    },
    {
        name:"jQuery",
        icon:"jQuery.png",
        path:"interview/lx?type=jQuery&i=jQuery"
    },
    {
        name:"react",
        icon:"react.png",
        path:"interview/lx?type=react&i=react"
    },
    {
        name:"typescript",
        icon:"typescript.png",
        path:"interview/lx?type=typescript&i=typescript"
    },
    {
        name:"Vue",
        icon:"Vue.png",
        path:"interview/lx?type=vue&i=vue"
    },
    {
        name:"前端安全",
        icon:"qdaq.png",
        path:"interview/lx?type=前端安全&i=qdaq"
    },
    {
        name:"设计模式",
        icon:"sjms.png",
        path:"interview/lx?type=设计模式&i=sjms"
    },
    {
        name:"工程化",
        icon:"gch.png",
        path:"interview/lx?type=工程化&i=gch"
    },
    {
        name:"NodeJs",
        icon:"nodejs.png",
        path:"interview/lx?type=NodeJs&i=nodejs"
    }
    ,
    {
        name:"性能优化",
        icon:"xnyh.png",
        path:"interview/lx?type=性能优化&i=xnyh"
    }
    ,
    {
        name:"敬请期待",
        icon:"jqqd.png",
        path:""
    },
    {
        name:"敬请期待",
        icon:"jqqd.png",
        path:""
    },
    {
        name:"敬请期待",
        icon:"jqqd.png",
        path:""
    }
]