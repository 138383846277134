import React, { Component } from 'react'
import TypeChild from './children/TypeChild.jsx'
// import Subject from './children/Subject.jsx'
import QuickNav from './children/QuickNav.jsx'
import TagList from './children/TagList.jsx'
import FollowMe from './children/FollowMe.jsx'
import MiniApp from './children/MiniApp.jsx'
import {subjectList,tagList,qNabList} from "./data.js"
import { getSubjectList } from '../../../api/index.js'
import { Link } from 'react-router-dom'
import "./index.css"
export default class Type extends Component {
    state = {
        subjectList,
        tagList,
        qNabList,
        aritlist:[]
    }
    componentDidMount(){
      getSubjectList({
        url: "http://cy.cylbm.cn/Management/ajax/updatePro.ashx?type=Loadproductlist",//'/vue-element-admin/article/list',
        method: 'get',
        params: {len:14,fy:1,typeac:"all"}
      }).then(res => {
        this.setState({
          aritlist:res.detalis
        })
      }).catch(()=>{})
    }
  render() {
    if(this.props.direction === "left"){
        return (
            <div className='type'>
              {/* <TypeChild title='科目'>
                  <Subject subjectList={this.state.subjectList} />
              </TypeChild> */}
              <TypeChild title=''>
                <QuickNav qNabList={this.state.qNabList} />
              </TypeChild>
              <TypeChild title="最近文章">
                  <div className='tagCon'>
                      {
                          this.state.aritlist.map((item,index)=>{
                            if(index > 4){
                              return (
                                  <Link to={"/articleDetails?id=" + item.id} key={'arit' + index} className='aritItem'>
                                      <div>{item.title}</div>
                                  </Link>
                              )
                            }
                          })
                      }
                  </div>
              </TypeChild>
              
            </div>
          )
    }else{
        return (
            <div className='type'>
              <TypeChild title=''>
                 <FollowMe />
              </TypeChild>
              <TypeChild title="小程序">
                <MiniApp />
              </TypeChild>
              <TypeChild title="标签">
                <TagList tagList={this.state.tagList} />
              </TypeChild>
            </div>
          )
    }

  }
}
