import React, { Component } from 'react'
import { getSubjectList } from '../../api'
import qs from "qs"
import "./index.css"
export default class ArticleDetails extends Component {
    state = {
        detalis:{}
        //qs.parse(this.props.location.search.slice(1)).
    }
    componentWillReceiveProps(x){
        this.getList(qs.parse(x.location.search.slice(1)).id)
    }
    componentDidMount(){
        this.getList(qs.parse(this.props.location.search.slice(1)).id)
    }
    getList =(id)=>{
        document.documentElement.scrollTop = 0;

        getSubjectList({
            url:"http://cy.cylbm.cn/Management/ajax/updatePro.ashx?type=Loadproductdetails",
            method:"get",
            params:{
                id:id
            }
        }).then((res) => {
            res.content =  decodeURIComponent(atob(res.content))
            //识别内容中是否包含中文，没有就再转一遍
            let reg = /[\u4E00-\u9FFF]+/g
           // alert(reg.test(res.content))
            if(!reg.test(res.content)){
                //预防第一遍内容转换不成功
                res.content =  decodeURIComponent(atob(res.content))
            }
            this.setState({
                detalis:res
            })
            
        })
    }
  render() {
    return (
      <div className='detailCon'>
        <div className='detailTitle'>
          <div onClick={()=>this.props.history.goBack()}>&lt;</div>
          {"文章详情"}
          {/* {this.state.detailTitle} */}
        </div>
        <div className='detextCon'>
            <div className='deTitle'>{this.state.detalis.title}</div>
            <div className='deInfo'>
                <div>编辑：{this.state.detalis.author}</div>
                <div>发布时间：{(new Date(this.state.detalis.display_time).toLocaleDateString())}</div>
            </div>
            <div className='articleDcode' style={{marginTop:"1rem"}}>
                <img src={"http://cy.cylbm.cn/"+this.state.detalis.image_uri} alt="我爱学前端" width={"100%"} />
                <div dangerouslySetInnerHTML={{__html:(this.state.detalis.content)}}>
                    
                </div>
            </div>
        </div>
      </div>
    )
  }
}
